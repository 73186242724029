import React, { useEffect, useRef } from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View,Platform } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { NativeBaseProvider, extendTheme, Container, Icon,Button, } from 'native-base';
import { MaterialIcons, Ionicons, MaterialCommunityIcons,Feather } from '@expo/vector-icons';
import HomeScreen from './screens/Home';
import LoginScreen from './screens/Login';
import ForgotScreen from './screens/Forgot';
import CheckinScreen from './screens/Checkin';
import CheckoutScreen from './screens/Checkout';
import { apiUrl } from './utils/services';
import Base64 from 'Base64';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Image } from 'expo-image';
const Stack = createStackNavigator();


//GLOBAL VARIABLES
global.BaseUrl='https://viafoneerp.azurewebsites.net/';
//global.BaseUrl = 'http://185.247.117.138/';
global.Id = -1;
global.accessToken = '';
global.Roles = '';
global.refreshToken = '';
global.Email = '';
global.FullName = '';
global.Initials = '';
global.Image = '';
global.Loxysoft_id = '';

function LogoTitle() {
  return (
    <Image
      style={{ width: 150, height: 20, }}
      source={require('./assets/main-login-logo.png')}
    />
  );
}

export default function App() {
  const [deviceid,setDeviceid] = React.useState(null);
  const [baseroute,setbaseroute] = React.useState('Login');
  const [checking,setchecking] = React.useState(true);
  const url = apiUrl();
  

  const registerForPushNotificationsAsync= async()=> {
    let token;
    if (Device.isDevice) {
        //alert("hi22");
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
            const { status } = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        }
        if (finalStatus !== 'granted') {
            alert('Failed to get push token for push notification!');
            return;
        }
        token = (await Notifications.getExpoPushTokenAsync()).data;
        //console.log(token);
        this.pushtoken = token;
        setDeviceid(this.pushtoken);
  //console.log('pushtoken',this.pushtoken);
    } else {
        alert('Must use physical device for Push Notifications');
    }

    if (Platform.OS === 'android') {
        Notifications.setNotificationChannelAsync('default', {
            name: 'default',
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [ 0, 250, 250, 250 ],
            lightColor: '#FF231F7C'
        });
    }

    return token;
}

  const autologin = async (deviceid) => {

    let authvalue = '';

    authvalue = await AsyncStorage.getItem("autologin") || '';

    //console.log('authvalue',authvalue);
    

    if(authvalue != '')
    {
      //console.log('authvalue',global.BaseUrl);
        //this.setState({loading:true});
        //alert("Hi");
        fetch(global.BaseUrl+'api/auth/token', {
            method: "POST",
            headers: {
                'Authorization': 'Basic '+authvalue, 
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                device_id: '1042',
                device: Platform.OS,
                version: Platform.Version,
            })

            }).then((response) => response.json())
            .then((responseData) => {

                //console.log ('responseData',responseData);
                
                //this.setState({autologinTry:true});
                //this.setState({loading:false});

                if(responseData.Id)
                {
                    //alert(responseData.Id);
                    
                    //console.log ("Success",responseData);
                    global.Id = responseData.Id;
                    global.accessToken = responseData.accessToken;
                    global.Roles = responseData.Roles;
                    global.refreshToken = responseData.refreshToken;
                    global.Email = responseData.Email;
                    global.FullName = responseData.FullName;
                    global.Image = responseData.image_full_path;
                    //global.Initials = this.(responseData.FullName);
                    global.Loxysoft_id=responseData.Loxysoft_id;

                    setbaseroute('Home');
                    setchecking(false);

                    //NavigationService.reset('Drawer');
                    //NavigationService.navigate('RecruitmentBonus');
                }
                else {
                  setchecking(false);
                }


            }).catch(function(error){

                console.log (error);
                setchecking(false);
                //this.setState({loading:false});
            }
        );
    }
    else
    {
      //alert('checking fail');
      setchecking(false);
       // this.setState({autologinTry:true});
    }
};

useEffect(()=>{

  // if(deviceid==null) {
  //   registerForPushNotificationsAsync();
  // }
  // else {
    
  // }
  autologin(deviceid);
  
}, [deviceid]);

  return !checking ?( 
    <NativeBaseProvider>
      <NavigationContainer>
        <Stack.Navigator initialRouteName= {baseroute} >
          <Stack.Screen
                name="Login"
                component={LoginScreen}
                options={{
                  headerShown: false,
                  gestureEnabled: false
                }}
              />
              <Stack.Screen
							name="Forgot"
							component={ForgotScreen}
							options={{
								headerShown: false,
								gestureEnabled: false
							}}
						/>
            <Stack.Screen
                name="Home"
                component={HomeScreen}
                options={{
                  headerShown: false,
                  gestureEnabled: false
                }}
              />
              <Stack.Screen
							name="Checkin"
							component={CheckinScreen}
							options={{
                headerShown: false,
								gestureEnabled: false
							}}
						/>
            <Stack.Screen
							name="Checkout"
							component={CheckoutScreen}
							options={{
                headerShown: false,
								gestureEnabled: false
							}}
						/>
              
            {/* <View style={styles.container}>
              <Text>Open up App.js to start working on your app!</Text>
              <StatusBar style="auto" />
            </View> */}
        </Stack.Navigator>
      </NavigationContainer>
    </NativeBaseProvider>
    
  ) :<NativeBaseProvider>
      <Image
  style={{width:"100%",height:"100%"}}
  source= {require('./assets/bg_overlayed.jpg')}
  alt="splashbg"
  />
     </NativeBaseProvider>;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
