import React, { useEffect, useRef } from 'react';
import { StyleSheet, TouchableOpacity, View, Platform, KeyboardAvoidingView,Dimensions,ImageBackground,Image } from 'react-native';
import {
	VStack,
	Center,
	Link,
	Box,
	Text,
	Avatar,
	Icon,
	useToast,
	Button,
	HStack,
	FormControl,
	Input,
	Pressable,
	Divider,
} from 'native-base';
import { MaterialIcons, Ionicons, MaterialCommunityIcons,Feather } from '@expo/vector-icons';
import logo from '../../assets/main-login-logo.png';
//import { apiUrl } from '../../utils/services';
const headerData = { 'Content-Type': 'application/json' };

function ForgotScreen(props) {
	
	const { nav } = props;
	const toast = useToast();
	const id = 'test-toast';
	//const url = apiUrl();
	const deviceWidth = Dimensions.get('window').width;
	const deviceHeight = Dimensions.get('window').height;
	const image = require('../../assets/bg_overlayed.jpg');
	const [loading, setLoading] = React.useState(false);
	const [username, setUsername] = React.useState({username: ''});

	const notification = (text, msg) => {
		toast.show({
			title: text,
			status: 'warning',
			description: msg,
			placement : 'top'
		});
	};



	const login = () =>
    {

        if(username.username !=='') {
            //this.setState({loading: true});
			setLoading(true);

            //console.log(global.BaseUrl + 'api/EmployeeNew/forgotpassword');
            fetch(global.BaseUrl + 'api/EmployeeNew/forgotpassword', {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    {
                        email: username
                    }
                )
            }).then((response) => response.json())
                .then((responseData) => {

                    console.log("Success");
                    console.log(responseData);

                    //this.setState({loading: false});
					setLoading(false);
					notification('Submit error',responseData.message);

                    //NavigationService.navigate('MemberSignIn');
					nav.navigate('Login');

                }).catch(function (error) {
                    //this.setState({loading: false});
					setLoading(false);
                    console.log(error);
                }
            );
        }
        else
        {
            
			notification("Fel, E-posten ska inte vara tom.");
        }
    };

	return <Box flex={1}>

				<ImageBackground source={image} resizeMode="cover" style={styles.image}>
					<Box justifyContent={"center"} alignItems={"center"} >
						<Image
							style={{width:"60%",height:30}}
							source= {require('../../assets/main-login-logo.png')}
							alt="logobg"
							/>
					</Box>
					
					<Center>
						{loading === false ?
							<Box p="2" py="8" w="90%" maxW="390">
								<VStack space={3} mt="2" >
									<FormControl isRequired>
									<FormControl.Label _text={{
											color: "#fff",
											fontSize: 'md',
											bold: true
										}}>
										E-post</FormControl.Label>
										
										<Input size={'lg'} color="white" colorScheme={'warmGray'} focusOutlineColor={'#fff'}  onChangeText={(text) => setUsername({username: text})} />
									</FormControl>
									
									<Button mt="2" size={"lg"} colorScheme="orange" onPress={() => {
										login();
									}}>
										<HStack space={5} justifyContent="space-between">
											<Text fontSize="sm" color={"white"} bold style={{textTransform: "uppercase"}}>Skicka</Text>
											<MaterialCommunityIcons name="arrow-right" size={24} color="white" />
										</HStack>
									</Button>
									
								</VStack>
							</Box>
						:
						false
						}
					</Center>
				</ImageBackground>				
				
			</Box>
	
}

export default function Forgot({ navigation }) {
	return (
		<KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={{ flex: 1 }}>		
		
			<ForgotScreen style={{ width: '100%' }} nav={navigation} />
				
		</KeyboardAvoidingView>
	);
}


const styles = StyleSheet.create({
	image: {
		flex: 1,
		justifyContent: 'center',
	  },
	  text: {
		color: 'white',
		fontSize: 42,
		lineHeight: 84,
		fontWeight: 'bold',
		textAlign: 'center',
		backgroundColor: '#000000c0',
	  },
});
