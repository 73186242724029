import React, { useEffect, useRef } from 'react';
import { StyleSheet, TouchableOpacity, View, Platform, KeyboardAvoidingView,Dimensions } from 'react-native';
import {
	VStack,
	Center,
	Link,
	Box,
	Text,
	Avatar,
	Icon,
	useToast,
	Button,
	HStack,
	FlatList,
	ScrollView,
	Pressable,
	Divider,
} from 'native-base';
import { MaterialIcons, Ionicons, MaterialCommunityIcons,Feather } from '@expo/vector-icons';
import logo from '../../assets/main-login-logo.png';
import { Image } from 'expo-image';
const headerData = { 'Content-Type': 'application/json' };

function CheckinScreen(props) {
	
	const { nav } = props;

	const deviceWidth = Dimensions.get('window').width;
	const deviceHeight = Dimensions.get('window').height;
	const [CheckingoutStatus,setCheckingoutStatus] = React.useState({checkedIn:false,checkedOut:false,attendance_id:'',modalVisible:false,});
	const [checkedOut,setcheckedOut] = React.useState(false);
	const [checkinToken,setcheckinToken] = React.useState(null);
	//const [modalVisible,setmodalVisible] = React.useState(false);
	const [notesSet,setnotesSet] = React.useState(false);

	useEffect(()=> {
		getCheckinStatus();
		getCheckinToken();
	},[]);


	const getCheckinToken = () =>
    {
        //this.setState({loading:true});

        fetch(global.BaseUrl+'api/EmployeeNew/LoginToken', {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json;',
                'Authorization': 'Bearer '+global.accessToken,
            }
        }).then((response) => response.json())
            .then((responseData) => {

                //console.log ('responseDataToken',responseData);
                setcheckinToken({ctoken:responseData.ctoken, type:"checkout"});
                //console.log('setcheckinToken',setcheckinToken);

                

            }).catch(function(error){
                console.log (error);
            }
        );
    };

	const getCheckinStatus = () =>
    {
		//console.log (modalVisible);
		if(CheckingoutStatus.modalVisible  == false && notesSet == false)
        {

			fetch(global.BaseUrl+'api/EmployeeNew/checkloginstatus', {
				method: "GET",
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json;',
					'Authorization': 'Bearer '+global.accessToken,
				}
			}).then((response) => response.json())
				.then((responseData) => {

					console.log ('responseDataStatus',responseData);
					setCheckingoutStatus({checkedIn:responseData.checkin, checkedOut:responseData.checkout, attendance_id:responseData.attendance_id, modalVisible:responseData.extratime});

				}).catch(function(error){
					console.log (error);
				}
			);
		}
    };

	const sendExtraInfo = () =>
    {
        var notes = this.state.notes;
        notes = notes.replace(/[^a-zA-Z0-9]/g, "");
        notes = notes.trim();
        
        if(this.state.notes.length >= 10 && notes.length>10)
        {
            fetch(global.BaseUrl+'api/EmployeeNew/extratimenotes', {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json;',
                    'Authorization': 'Bearer '+global.accessToken,
                },
                body: JSON.stringify({
                    notes: this.state.notes,
                    attendance_id: this.state.attendance_id
                })

            }).then((response) => response.json())
                .then((responseData) => {

                    console.log (responseData);
                    console.log ("Success "+global.BaseUrl+'api/EmployeeNew/extratimenotes');

                    this.setState({modalVisible:false, notesSet:true});

                }).catch(function(error){                    
                    console.log (error);
                }
            );
        }
        else
        {
            Alert.alert(
                "",
                "Måste skriva giltig text.",
                [
                    {
                        text: 'OK',
                        onPress: () => console.log('Cancel Pressed'),
                        style: 'cancel',
                    },
                ],
                {cancelable: false},
            );
        }
    };

	return <Box flex={1}>
			<Box safeAreaTop p={2} bg={"#020202"} style={{flexDirection:"row",justifyContent:"space-between"}}>
                <View>
                    <Pressable onPress={()=> {nav.navigate('Home')}}>
                        <MaterialCommunityIcons name="arrow-left" size={32} color="white" style={{marginLeft:20}} />
                    </Pressable>
                </View>
                <View>
                    <Image
                        style={{ width: 150, height: 20, }}
                        source={require('../../assets/main-login-logo.png')}
                        alt='checkinlogo'
                        />
                </View>
                <View>
                    <Pressable onPress={() => {
                            getCheckinToken()
                        }}>
                        <MaterialCommunityIcons name="refresh" size={32} color="white" style={{marginRight:20}} />
                    </Pressable>
                </View>
            </Box>
			<View style={{padding:10,backgroundColor:"#ED5D02",alignItems:"center"}}>
                <Text bold fontSize="lg">Checka Out</Text>
            </View>
            <Box p={10} backgroundColor={"white"} height={"70%"} justifyContent={"center"} alignItems={"center"} >
                <Center>                
					{!CheckingoutStatus.checkedOut && checkinToken!=null ?
                        <Image source={{uri:"http://api.qrserver.com/v1/create-qr-code/?data="+JSON.stringify(checkinToken)+"&size="+deviceWidth/1.5+"x"+deviceWidth/1.5+"&bgcolor=ffffff&color=970000"}} style={{width:deviceWidth/1.5, height:deviceWidth/1.5}} resizeMode={"contain"} alt='checkinQR' />
                        :
                        false
                    }
                    {CheckingoutStatus.checkedOut ?
                        <MaterialCommunityIcons name="check-circle" size={24} color="Black" />
                        :
                        false
                    }                    
                </Center>
            </Box>
            <Box backgroundColor={"white"}  pb={10}>
                <VStack space={5} alignItems={"center"}>
                    <Text fontSize="lg" color={"#020202"}>Använd denna QR-kod för att checka ut.</Text>
                    <Text fontSize="lg" style={{textAlign:"center",color:"red"}}>Vid missad utcheckning kommer din lön att påverkas.</Text>
                </VStack>
            </Box>
		</Box>
	
}

export default function Checkin({ navigation }) {
	return (
		<KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={{ flex: 1 }}>		
		
			<CheckinScreen style={{ width: '100%' }} nav={navigation} />
				
		</KeyboardAvoidingView>
	);
}


const styles = StyleSheet.create({
	bgimage: {
		flex: 1,
		resizeMode: 'cover',
		position: 'absolute',
		width: '100%',
		height: '100%',
		justifyContent: 'center'
		//marginTop: 20
	},
	logo : {
		width : '80%',
		height : 180
	},
});
