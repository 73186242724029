import React, { useEffect, useRef } from 'react';
import { StyleSheet, TouchableOpacity, View, Platform, KeyboardAvoidingView,Dimensions } from 'react-native';
import {
	VStack,
	Center,
	Link,
	Box,
	Text,
	Avatar,
	Icon,
	useToast,
	Button,
	HStack,
	FlatList,
	ScrollView,
	Pressable,
	Divider,
} from 'native-base';
import { MaterialIcons, Ionicons, MaterialCommunityIcons,Feather } from '@expo/vector-icons';
import logo from '../../assets/main-login-logo.png';
import { Image } from 'expo-image';
const headerData = { 'Content-Type': 'application/json' };

function CheckinScreen(props) {
	
	const { nav } = props;
	const deviceWidth = Dimensions.get('window').width;
	const deviceHeight = Dimensions.get('window').height;
	const [CheckingStatus,setCheckingStatus] = React.useState({checkedIn:false,checkedOut:false});
	const [checkedOut,setcheckedOut] = React.useState(false);
	const [checkinToken,setcheckinToken] = React.useState(null);

	useEffect(()=> {
		getCheckinStatus();
		getCheckinToken();
	},[]);


	const getCheckinToken = () =>
    {
        //this.setState({loading:true});

        fetch(global.BaseUrl+'api/EmployeeNew/LoginToken', {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json;',
                'Authorization': 'Bearer '+global.accessToken,
            }
        }).then((response) => response.json())
            .then((responseData) => {

                //console.log ('responseDataToken',responseData);
                setcheckinToken({ctoken:responseData.ctoken, type:"checkin"});
                //console.log('setcheckinToken',setcheckinToken);

                

            }).catch(function(error){
                console.log (error);
            }
        );
    };

	const getCheckinStatus = () =>
    {

        fetch(global.BaseUrl+'api/EmployeeNew/checkloginstatus', {
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json;',
                'Authorization': 'Bearer '+global.accessToken,
            }
        }).then((response) => response.json())
            .then((responseData) => {

                //console.log ('responseDataStatus',responseData);
                setCheckingStatus({checkedIn:responseData.checkin, checkedOut:responseData.checkout});

            }).catch(function(error){
                console.log (error);
            }
        );
    };

	return <Box flex={1}>
            <Box safeAreaTop p={2} bg={"#020202"} style={{flexDirection:"row",justifyContent:"space-between"}}>
                <View>
                    <Pressable onPress={()=> {nav.navigate('Home')}}>
                        <MaterialCommunityIcons name="arrow-left" size={32} color="white" style={{marginLeft:20}} />
                    </Pressable>
                </View>
                <View>
                    <Image
                        style={{ width: 150, height: 20, }}
                        source={require('../../assets/main-login-logo.png')}
                        alt='checkinlogo'
                        />
                </View>
                <View>
                    <Pressable onPress={() => {
                            getCheckinToken()
                        }}>
                        <MaterialCommunityIcons name="refresh" size={32} color="white" style={{marginRight:20}} />
                    </Pressable>
                </View>
            </Box>
			<View style={{padding:10,backgroundColor:"#ED5D02",alignItems:"center"}}>
                <Text bold fontSize="lg">Checka In</Text>
            </View>
            <Box p={10} backgroundColor={"white"} height={"70%"} justifyContent={"center"} alignItems={"center"} >
                <Center>
                    {!CheckingStatus.checkedIn && checkinToken!=null ?
                        <Image source={{uri:"http://api.qrserver.com/v1/create-qr-code/?data="+JSON.stringify(checkinToken)+"&size="+deviceWidth/1.5+"x"+deviceWidth/1.5+"&bgcolor=ffffff&color=005c28"}} style={{width:deviceWidth/1.5, height:deviceWidth/1.5}} resizeMode={"contain"} alt='checkinQR' />
                        :
                        false
                    }
                    {CheckingStatus.checkedIn ?
                        <MaterialCommunityIcons name="check-circle" size={24} color="Black" />
                        :
                        false
                    }
                    {/* <Text bold fontSize="lg">QR Code</Text>*/}
                </Center>
            </Box>
            <Box backgroundColor={"white"}  pb={10}>
                <VStack space={5} alignItems={"center"}>
                    <Text fontSize="lg" color={"#020202"}>Använd denna QR-kod för att checka in.</Text>
                    <Text fontSize="lg" style={{textAlign:"center",color:"red"}}>Vid missad incheckning kommer din lön att påverkas.</Text>
                </VStack>
            </Box>
		</Box>
	
}

export default function Checkin({ navigation }) {
	return (
		<KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={{ flex: 1 }}>		
		
			<CheckinScreen style={{ width: '100%' }} nav={navigation} />
				
		</KeyboardAvoidingView>
	);
}


const styles = StyleSheet.create({
	bgimage: {
		flex: 1,
		resizeMode: 'cover',
		position: 'absolute',
		width: '100%',
		height: '100%',
		justifyContent: 'center'
		//marginTop: 20
	},
	logo : {
		width : '80%',
		height : 180
	},
});
