import React, { useEffect, useRef } from 'react';
import { StyleSheet, TouchableOpacity, View, Platform, KeyboardAvoidingView,Dimensions,ImageBackground } from 'react-native';
import {
	VStack,
	Center,
	Link,
	Box,
	Text,
	Avatar,
	Icon,
	useToast,
	Button,
	HStack,
	FormControl,
	Input,
	Pressable,
	Divider,
} from 'native-base';
import { MaterialIcons, Ionicons, MaterialCommunityIcons,Feather } from '@expo/vector-icons';
import logo from '../../assets/main-login-logo.png';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { apiUrl } from '../../utils/services';
import Base64 from 'Base64';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { Image } from 'expo-image';
const headerData = { 'Content-Type': 'application/json' };

function LoginScreen(props) {
	
	const { nav } = props;
	const toast = useToast();
	const id = 'test-toast';
	const url = apiUrl();
	const deviceWidth = Dimensions.get('window').width;
	const deviceHeight = Dimensions.get('window').height;
	const image = require('../../assets/bg_overlayed.jpg');
	const [show, setShow] = React.useState(false);
	const [ username, setUsername ] = React.useState(null);
	const [ password, setPassword ] = React.useState(null);
	const [ loading, setloading ] = React.useState(false);
	const [ emailid, setEmailid ] = React.useState(null);
	const [deviceid,setDeviceid] = React.useState(null);
	const [ forgotpassword, setForgotpassword ] = React.useState(false);


	const notification = (text, msg) => {
		toast.show({
			title: text,
			status: 'warning',
			description: msg,
			placement : 'top'
		});
	};
	Notifications.setNotificationHandler({
		handleNotification: async () => ({
		  shouldShowAlert: true,
		  shouldPlaySound: false,
		  shouldSetBadge: false,
		}),
	  });

	   const getInitials = (string) => {
        let names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };


    const registerForPushNotificationsAsync= async()=> {
        let token;
        if (Device.isDevice) {
            const { status: existingStatus } = await Notifications.getPermissionsAsync();
            let finalStatus = existingStatus;
            if (existingStatus !== 'granted') {
                const { status } = await Notifications.requestPermissionsAsync();
                finalStatus = status;
            }
            if (finalStatus !== 'granted') {
                alert('Failed to get push token for push notification!');
                return;
            }
            token = (await Notifications.getExpoPushTokenAsync()).data;
            //console.log(token);
            this.pushtoken = token;
			setDeviceid(this.pushtoken);
			//console.log('pushtoken',this.pushtoken);
        } else {
            alert('Must use physical device for Push Notifications');
        }
    
        if (Platform.OS === 'android') {
            Notifications.setNotificationChannelAsync('default', {
                name: 'default',
                importance: Notifications.AndroidImportance.MAX,
                vibrationPattern: [ 0, 250, 250, 250 ],
                lightColor: '#FF231F7C'
            });
        }
    
        return token;
    }

	useEffect(()=>{
		//let newtoken =registerForPushNotificationsAsync();
		//console.log("token", newtoken);
		//console.log("Opening "+this.pushtoken);
		// if(deviceid==null) {
		// 	registerForPushNotificationsAsync();
		// }
		// else {
		// 	//alert(deviceid);
		// }
		
	}, [deviceid]);

	const login = async (name, pwd) => {
		//setloading(true);
	console.log('Base64',Base64.btoa(name+":"+pwd));
		fetch(url+'api/auth/token', {
            method: "POST",
            headers: {
                'Authorization': 'Basic '+Base64.btoa(name+":"+pwd), 
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },

            body: JSON.stringify({
                device_id: 
				'1042',
                device: 'IOS',
                version: Platform.Version,
            })
            }).then((response) =>response.json())
            .then((responseData) => {
                console.log ("responseData", responseData);
                if(responseData.Id)
                {

                    AsyncStorage.setItem("autologin", Base64.btoa(name+":"+pwd));
                    //this.setState({loading:false});

                    console.log ("Success");
                    global.Id = responseData.Id;
                    global.accessToken = responseData.accessToken;
                    global.Roles = responseData.Roles;
                    global.refreshToken = responseData.refreshToken;
                    global.Email = responseData.Email;
                    global.FullName = responseData.FullName;
                    global.Image = responseData.image_full_path;
                    global.Initials = getInitials(responseData.FullName);
                    global.Loxysoft_id=responseData.Loxysoft_id;

                   // NavigationService.reset('Drawer');
                    //NavigationService.navigate('MySchedule');
					//nav.navigate('Home');
					nav.reset({ routes: [ { name: 'Home' } ] });
					 AsyncStorage.getItem('token', responseData?.data?.accessToken?.tostring());
					AsyncStorage.getItem('username', name.tostring());
					 AsyncStorage.getItem('password', pwd.tostring());
				
                }
                else {
                    //this.setState({loading:false});
					notification('Login error', responseData?.message);
                }

            }).catch(function(error){
                console.log (error);
           
            }
        );

	}

	return <Box flex={1} bg={"orange.400"} justifyContent={"center"} >
					
				{/* <ImageBackground source={image} resizeMode="cover" style={styles.image}> */}
					
					<Box justifyContent={"center"} alignItems={"center"} >
						<Image
							style={{width:"60%",height:30}}
							source= {require('../../assets/main-login-logo.png')}
							alt="logobg"
							/>
					</Box>
					<Center>
					
					
						<Box p="2" py="8" w="90%" maxW="390">
							<VStack  mt="2" >
								<Box bg={"#fff"} p={5}>
								<FormControl isRequired>
								{/* <FormControl.Label _text={{
										color: "#fff",
										fontSize: 'md',
										bold: true
									}}>
									Anvandarnamn</FormControl.Label> */}
									
									<Input marginBottom={5} size={'lg'} color="black" placeholder='Anvandarnamn' onChange={(e) => setUsername(e.nativeEvent.text)} style={{backgroundColor:"#fff",}} borderWidth={0}
									 />
									</FormControl>
								<FormControl isRequired>
									{/* <FormControl.Label _text={{
										color: "#fff",
										fontSize: 'md',
										bold: true
									}}>
									Losenord</FormControl.Label> */}
									<Input placeholder='Losenord' size={'lg'} type={show ? "text" : "password"}  color="black"
									InputRightElement={<Pressable  onPress={() => setShow(!show)} >
									<Icon as={<MaterialIcons name={show ? "visibility" : "visibility-off"} />} size={5} mr="2" color="black" />
								  </Pressable>} onChange={(e) => setPassword(e.nativeEvent.text)} style={{backgroundColor:"#fff",}} borderWidth={0} backgroundColor={"#fff"}/>
								</FormControl>
								</Box>
								<Button  size={"lg"} colorScheme="orange" 
									onPress={() => {
										username == null
											? notification('Username', 'should not be empty.')
											: password == null ? notification('Password', 'Should not be empty.') : null;
										username != null && password != null ? login(username, password) : null;
									}}>
										
									<HStack space={5} width={300}  justifyContent={"space-between"} alignItems={"center"}>										
										<Text fontSize="sm" color={"white"} bold style={{textTransform: "uppercase"}}>Logga in</Text>										
										<MaterialCommunityIcons name="arrow-right" size={24} color="white" />										
									</HStack>
								</Button>
								<Link alignSelf="flex-end" mt="1" onPress={() => {
									nav.navigate('Forgot');
								}}>
									<Text bold fontSize="sm" color={"white"} >Glomt losenord?</Text>
								</Link>
							</VStack>
						</Box>
					</Center>
				{/* </ImageBackground>				 */}
				
			</Box>
	
}

export default function Login({ navigation }) {
	return (
		<KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={{ flex: 1 }}>		
		
			<LoginScreen style={{ width: '100%' }} nav={navigation} />
				
		</KeyboardAvoidingView>
	);
}


const styles = StyleSheet.create({
	image: {
		flex: 1,
		justifyContent: 'center',
	  },
	  text: {
		color: 'white',
		fontSize: 42,
		lineHeight: 84,
		fontWeight: 'bold',
		textAlign: 'center',
		backgroundColor: '#000000c0',
	  },
});
