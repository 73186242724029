import React, { useEffect, useRef } from 'react';
import { StyleSheet, TouchableOpacity, View, Platform, KeyboardAvoidingView,Dimensions,	 } from 'react-native';
import {
	VStack,
	Center,
	Link,
	Box,
	Text,
	Avatar,
	Icon,
	useToast,
	Button,
	HStack,
	FlatList,
	ScrollView,
	Pressable,
	Divider,
} from 'native-base';
import { MaterialIcons, Ionicons, MaterialCommunityIcons,Feather } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import logo from '../../assets/main-login-logo.png';
import { Image } from 'expo-image';
const headerData = { 'Content-Type': 'application/json' };


function HomeScreen(props) {
	
	const { nav } = props;

	const deviceWidth = Dimensions.get('window').width;
	const deviceHeight = Dimensions.get('window').height;

	const data = [
		{
			name: 'Rekrytering',
			image: require('../../assets/images/btn-referral.png'),
			alt: 'Rekrytering'
		},
		{
			name: 'Statistik',
			image: require('../../assets/images/btn-referral.png'),
			alt: 'Statistik'
		},
		{
			name: 'Min Forsaljning',
			image: require('../../assets/images/btn-referral.png'),
			alt: 'Min Forsaljning'
		},
		{
			name: 'Profil',
			image: require('../../assets/images/btn-referral.png'),
			alt: 'Profil'
		},
		{
			name: 'Arbetsschema',
			image: require('../../assets/images/btn-referral.png'),
			alt: 'Arbetsschema'
		},
		{
			name: 'Begar franvaro',
			image: require('../../assets/images/btn-referral.png'),
			alt: 'Begar franvaro'
		},
		{
			name: 'Notiser',
			image: require('../../assets/images/btn-referral.png'),
			alt: 'Notiser'
		},
		{
			name: 'Kontakt',
			image: require('../../assets/images/btn-referral.png'),
			alt: 'Kontakt'
		}
	];

	const Headercomponent = ()=> {
		return(
			<Box safeAreaTop p={3} bg={"#020202"} style={{flexDirection:"row",justifyContent:"space-between",alignItems:'center'}}>
                <View>
                    
                </View>
                <View>
                    <Image
                        style={{ width: 150, height: 20, }}
                        source={require('../../assets/main-login-logo.png')}
                        alt='checkinlogo'
                        />
                </View>
                <View>
                    
                </View>
            </Box>
		);
	}

	return <Box flex={1}>
			<Headercomponent />
			<ScrollView p={2} >
				<Box p="4" backgroundColor={"white"}>
					<HStack space={10}>
						<Image alignSelf="center" source={{uri:global.Image}} style={{width:60,height:60,borderRadius:30}}>							
						</Image>
						<View>
							<VStack>
								<Text bold fontSize="md">{global.FullName}</Text>
								<Text fontSize="xs">{global.Email}</Text>
								<Text bold>ID: {global.Id}</Text>
							</VStack>
						</View>
						<TouchableOpacity onPress={()=>{
							alert("logout");
								AsyncStorage.removeItem("autologin");
								nav.navigate('Login');
							}}>
							<MaterialCommunityIcons name="logout" size={32} color="black"  />
						</TouchableOpacity>
						
					</HStack>
					<HStack justifyContent={"flex-end"}>
						<Image
							style={{ width: 50, height: 40, }}
							source={require('../../assets/instagram.png')}
							alt='instagram'
							/>
						<Image
							style={{ width: 50, height: 40, }}
							source={require('../../assets/facebook.png')}
							alt='facebook'
							/>
					</HStack>
				</Box>
				<Box mt={1} >
					<HStack space={1} justifyContent={"space-between"}>						
						<TouchableOpacity style={{padding:15,width:"50%",backgroundColor:"#fff",alignItems:"center"}} onPress={()=> {nav.navigate("Checkin") }}>
							<VStack alignItems={"center"}>
								<MaterialCommunityIcons name="clock-in" size={32} color="black" />
								<Text bold fontSize="md">Checka In</Text>
							</VStack>
						</TouchableOpacity>
						
						<TouchableOpacity style={{padding:15,width:"50%",backgroundColor:"#fff",alignItems:"center"}} onPress={()=> {nav.navigate("Checkout") }}>
							<VStack alignItems={"center"}>
								<MaterialCommunityIcons name="clock-out" size={32} color="black" />
								<Text bold fontSize="md">Checka Out</Text>
							</VStack>
						</TouchableOpacity>
					</HStack>
				</Box>
				{/* <Box mt={1} p={3} backgroundColor={"black"}>
					<VStack justifyContent={"center"} alignItems={"center"} >
						<Text bold fontSize="md" color={"white"} >Manadslon (6/2023)</Text>
						<Divider my="1" _light={{
								bg: "muted.400"
							}} />
						<Text bold color={"white"} mt={2}>
							<MaterialCommunityIcons name="cash-multiple" size={24} color="orange" /> 0 kr
						</Text>
						
					</VStack>
				</Box>
				<Box mt={1} p={3} backgroundColor={"orange.500"}>
					<VStack justifyContent={"center"} alignItems={"center"} >
						<Text bold fontSize="md" color={"white"} >Min narvaro</Text>
						<Divider my="1" _light={{
								bg: "muted.300"
							}} />
						<HStack space={3} alignItems={"center"}>
							<MaterialCommunityIcons name="timer-sand" size={24} color="black" />
							<VStack>
								<Text bold fontSize="sm" color={"white"} >Loxysoft: 00:00:00 Timmar</Text>
								<Text bold fontSize="sm" color={"white"} >E-Check in: 00:00:00 Timmar</Text>
							</VStack>
						</HStack>
						
					</VStack>
				</Box>
				
				<FlatList data={data}
				numColumns={2}
				renderItem={({item}) => 
					<Box p="3" width={"50%"} backgroundColor={"white"} mt={2} mr={2}>
						<VStack alignItems={"center"}>
							<Image
								style={{ width: 100, }}
								source={item.image}
								alt={item.alt}
								/>
							<Text bold mt={2} fontSize="md">{item.name}</Text>
						</VStack>
					</Box>
				} keyExtractor={item => item.id} />
				<View style={{height:20}} /> */}
			</ScrollView>
		</Box>
	
}

export default function Home({ navigation }) {
	return (
		<KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={{ flex: 1 }}>		
		
			<HomeScreen style={{ width: '100%' }} nav={navigation} />
				
		</KeyboardAvoidingView>
	);
}


const styles = StyleSheet.create({
	bgimage: {
		flex: 1,
		resizeMode: 'cover',
		position: 'absolute',
		width: '100%',
		height: '100%',
		justifyContent: 'center'
		//marginTop: 20
	},
	logo : {
		width : '80%',
		height : 180
	},
});
